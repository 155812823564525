@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Nunito', Arial, Helvetica, sans-serif;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-ExtraBold.ttf');
  font-weight: 800;
}

.drawer {
  transition: all 400ms;
  transform: translateY(100%);
}

.drawer-enter,
.drawer-enter-done {
  transform: translateY(0px);
}

.drawer-exit,
.drawer-exit-done {
  transform: translateY(100%);
}

.uploaded-image {
  transition: all 300ms;
  opacity: 0;
}

.uploaded-image-enter,
.uploaded-image-enter-done {
  opacity: 100%;
}

.uploaded-image-exit,
.uploaded-image-exit-done {
  opacity: 0;
}

.btn-buy-now {
  @apply rounded-full text-primaryDark bg-brandYellow text-xs font-bold tracking-[0.0375rem] py-[0.5rem] px-[1.56rem];
}

@keyframes scan-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.scan-animation {
  animation: scan-animation 1000ms alternate infinite 10ms;
}
